import React from 'react';

import type { Deal } from '../../../types/Deal';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import { getLabel } from './getLabel';
import { getValue } from './getValue';
import styles from './styles/contract-allowance.css';

interface ContractAllowanceProps {
  deal: Deal;
  className?: string;
  type?: string;
  showLabel?: boolean;
}

const ContractAllowance: React.FC<ContractAllowanceProps> = ({
  deal,
  className,
  showLabel,
  type,
}) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { translate } = value;
      const containerStyle = className && styles[className] ? className : 'container';
      const displayValue = getValue(type, deal, translate);

      // Always return but only create block content if there is a value
      // We still want to display a value if it is 0 - e.g. 0MB of data
      if (displayValue || (!displayValue && typeof displayValue === 'number')) {
        return (
          <div className={styles[containerStyle]} data-type={type}>
            <strong className={styles.value}>{displayValue}</strong>
            {showLabel && <span className={styles.label}>{getLabel(type, deal, translate)}</span>}
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default ContractAllowance;
