import type { Translate } from '../types/Localiser';

export default (amount: number | null | undefined, translate: Translate): string => {
  if (typeof amount === 'undefined' || amount === null) {
    return translate('n/a');
  }
  if (Math.floor(amount) === 99999 || Math.floor(amount) === 999999) {
    return translate('unlimitedValue');
  }

  if (amount >= 1000) {
    let newAmount = amount;
    if (amount % 1024 === 0) {
      newAmount = Math.floor(amount / 1000) * 1000;
    }
    return newAmount / 1000 + translate('gigabyte');
  }

  return amount + translate('megabyte');
};
