import type { Deal } from '../../core/types/Deal';
import type { HoverItem } from '../../core/types/HoverItem';

export enum HoverOverTypes {
  STANDARD_RATE_NOTE = 'standard_rate_note',
  MINUTES_DESCRIPTION = 'minutes_description',
  TEXTS_DESCRIPTION = 'texts_description',
  DATA_DESCRIPTION = 'data_description',
  PRICE_NOTE = 'price_note',
}
export const getHoverOverItems = (deal: Deal, defaultTypes?: HoverOverTypes[]): HoverItem[] => {
  const types = defaultTypes || [
    HoverOverTypes.STANDARD_RATE_NOTE,
    HoverOverTypes.MINUTES_DESCRIPTION,
    HoverOverTypes.TEXTS_DESCRIPTION,
    HoverOverTypes.DATA_DESCRIPTION,
  ];
  const items: HoverItem[] = [];

  if (deal?.contract?.price_note && types.indexOf(HoverOverTypes.PRICE_NOTE) >= 0) {
    items.push({
      bold: '',
      normal: deal.contract.price_note,
    });
  }
  if (deal?.contract?.standard_rate_note && types.indexOf(HoverOverTypes.STANDARD_RATE_NOTE) >= 0) {
    items.push({
      bold: '',
      normal: deal.contract.standard_rate_note,
    });
  }
  if (
    deal?.contract?.minutes_description &&
    types.indexOf(HoverOverTypes.MINUTES_DESCRIPTION) >= 0
  ) {
    items.push({
      bold: 'Calls: ',
      normal: deal.contract.minutes_description,
    });
  }
  if (deal?.contract?.texts_description && types.indexOf(HoverOverTypes.TEXTS_DESCRIPTION) >= 0) {
    items.push({
      bold: 'Texts: ',
      normal: deal.contract.texts_description,
    });
  }
  if (deal?.contract?.data_description && types.indexOf(HoverOverTypes.DATA_DESCRIPTION) >= 0) {
    items.push({
      bold: 'Data: ',
      normal: deal.contract.data_description,
    });
  }

  return items;
};
