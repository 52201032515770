import React from 'react';

import getImageUrl from '../../../modules/getImageUrl';
import type { UIFilter } from '../../../types/UIFilter';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/filter.css';

interface FilterProps {
  values: UIFilter[];
  selectedValue: string;
  changeFilter: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  type?: string;
  filterKey?: string;
  className?: string;
}

const Filter: React.FC<FilterProps> = ({
  values,
  selectedValue,
  changeFilter,
  type,
  filterKey,
  className,
}) => {
  const {
    translate,
    genericSharedComponents: { LazyImage },
  } = useHawkWidgetContext();
  const containerStyle = className && styles[className] ? className : 'container';

  return (
    <div className={styles[containerStyle]}>
      <select
        className={styles.select}
        onChange={changeFilter}
        value={selectedValue}
        data-type={type}
        data-filter-key={filterKey}
      >
        {(values || []).map((value) => (
          <option
            value={value.value}
            disabled={value.disabled}
            key={`${value.value}-${value.desc}`}
          >
            {value.label}
          </option>
        ))}
      </select>
      <LazyImage
        src={getImageUrl('misc/arrow_down.png')}
        alt={translate('arrowAltText')}
        width={13}
        height={13}
        className={className === 'pocket' ? 'pocket-filter-arrow' : 'filter-arrow'}
      />
    </div>
  );
};

export default Filter;
