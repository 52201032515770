import React from 'react';

import type { Filter as FilterType } from '../../../types/Filter';
import Filter from '../Filter/Filter';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/filters-row.css';

interface FiltersRowProps {
  filters: FilterType[];
}

const FiltersRow: React.FC<FiltersRowProps> = (props) => {
  let { filters } = props;
  filters = filters || [];

  return (
    <Consumer>
      {(value): JSX.Element => {
        const { changeFilter } = value;
        let { selectedFilters } = value;
        selectedFilters = selectedFilters || {};

        return (
          <div className={styles.container}>
            {filters.map((filter) => (
              <Filter
                {...{
                  selectedValue: selectedFilters[filter.filter_key],
                  values: filter.values.map((v) => {
                    return {
                      value: v.value,
                      label: String(v.formatted_value),
                      disabled: false,
                    };
                  }),
                  type: filter.type,
                  filterKey: filter.filter_key,
                  changeFilter,
                  className: 'bordered-full-width',
                  key: filter.filter_key,
                }}
              />
            ))}
          </div>
        );
      }}
    </Consumer>
  );
};

export default FiltersRow;
