import React from 'react';

import Filters from '../../GenericComponents/Filters/Filters';
import Footer from '../../GenericComponents/Footer/Footer';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import MultiModelReviewContractsItems from './MultiModelReviewContractsItems';
import styles from './styles/multi-model-review-contracts.css';

const MultiModelReviewContracts: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        widgetTitle,
        loading,
        genericSharedComponents: { HawkTabs, Spinner, Title },
      } = value;

      return (
        <div className={styles.container}>
          {loading && <Spinner />}
          <Title title={widgetTitle} className="multimodelreview" />
          <Filters />
          <HawkTabs />
          <MultiModelReviewContractsItems />
          <Footer showDescription={true} showPoweredBy={true} />
        </div>
      );
    }}
  </Consumer>
);

export default MultiModelReviewContracts;
