import type { Deal } from '../types/Deal';
import type { Translate } from '../types/Localiser';
import productAmount from '../utils/productAmount';

import getMinutesValueDisplay from './getMinutesValueDisplay';

export const getMinutesDisplayValue = (deal: Deal, translate: Translate): string | number => {
  if (deal?.contract && typeof deal.contract.minutes === 'number') {
    return productAmount(deal.contract.minutes, translate);
  }
  if (deal?.contract && typeof deal.contract.minutes_value === 'number') {
    return getMinutesValueDisplay(deal, translate);
  }
  return '';
};

export const getMinutesDisplayLabel = (deal: Deal, translate: Translate): string => {
  if (deal?.contract && typeof deal.contract.minutes === 'number') {
    return ` ${translate('minutesDisplayMinutesLabel')}`;
  }
  if (deal?.contract && typeof deal.contract.minutes_value === 'number') {
    return ` ${translate('minutesDisplayMinutesValueLabel')}`;
  }
  return '';
};
