import React from 'react';

import Filters from '../../GenericComponents/Filters/Filters';
import Footer from '../../GenericComponents/Footer/Footer';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import MultiModelReviewRetailItems from './MultiModelReviewRetailItems';
import styles from './styles/multi-model-review-retail.css';

const MultiModelReviewRetail: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        widgetTitle,
        loading,
        genericSharedComponents: { HawkTabs, Spinner, Title },
      } = value;

      return (
        <div className={styles.container}>
          {loading && <Spinner />}
          <Title title={widgetTitle} className="multimodelreview" />
          <Filters />
          <HawkTabs />
          <MultiModelReviewRetailItems />
          <Footer className="transparent-container" showDescription={true} showPoweredBy={true} />
        </div>
      );
    }}
  </Consumer>
);

export default MultiModelReviewRetail;
