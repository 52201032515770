import { getMinutesDisplayLabel } from '../../../modules/getMinutesDisplay';
import type { Deal } from '../../../types/Deal';
import type { Translate } from '../../../types/Localiser';

export const getLabel = (
  type: string | undefined,
  deal: Deal,
  translate: Translate,
): string | null => {
  switch (type) {
    case 'data':
      return translate('dataBlockLabel');
    case 'length':
      return translate('contractLengthBlockLabel');
    case 'minutes':
      return getMinutesDisplayLabel(deal, translate);
    case 'speed':
      return translate('speedBlockLabel');
    case 'texts':
      return translate('textsBlockLabel');
    default:
      return null;
  }
};
