import formatContractLength from '../../../modules/formatContractLength';
import { getMinutesDisplayValue } from '../../../modules/getMinutesDisplay';
import getTextsValue from '../../../modules/getTextsValue';
import type { Deal } from '../../../types/Deal';
import type { Translate } from '../../../types/Localiser';
import formatPhoneAmount from '../../../utils/formatPhoneAmount';

export const getValue = (
  type: string | undefined,
  deal: Deal,
  translate: Translate,
): string | number | null => {
  switch (type) {
    case 'data':
      return deal?.contract && typeof deal.contract.data === 'number'
        ? formatPhoneAmount(deal.contract.data, translate)
        : null;
    case 'length':
      return formatContractLength(deal, translate);
    case 'minutes':
      return getMinutesDisplayValue(deal, translate);
    case 'speed':
      return deal?.contract?.speed
        ? translate('speedBlockValue', [String(deal.contract.speed)])
        : null;
    case 'texts':
      return getTextsValue(deal, translate);
    default:
      return null;
  }
};
