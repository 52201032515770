import React from 'react';

import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';
import { PoweredBy } from '../PoweredBy/loadable';

import styles from './styles/footer.css';

interface FooterProps {
  showDescription: boolean;
  showPoweredBy: boolean;
  className: string | null;
  label: string;
}

const Footer: React.FC<Partial<FooterProps>> = ({
  showDescription,
  showPoweredBy,
  className,
  label,
}) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        translate,
        dealData,
        params,
        loadMore,
        showLink,
        activeTab,
        genericSharedComponents: { AllDeals },
      } = value;

      if (showLink) {
        const containerStyle = className && styles[className] ? className : 'container';
        const dealsDisplayed = dealData.deals ? dealData.deals.length : 0;

        // Use distinct models names from dealData - will work for single or multiple models
        // & multiply models on placeholder or related models
        const modelNames = new Set();
        (dealData.deals || []).forEach((deal) => {
          // sending a request e.g "ps4,Everything" will cause displaying more Everything matches
          // than ps4 ones, in the comparison chart
          if (deal?.model && deal?.product_type && deal.product_type !== 200) {
            modelNames.add(deal.model);
          }
        });

        return (
          <div className={styles[containerStyle]}>
            {showDescription && (
              <div className={styles.description}>{translate('hawkFooterDescription')}</div>
            )}
            {!loadMore && dealsDisplayed < dealData.totalDeals && (
              <AllDeals
                {...{
                  params: {
                    labelTextGamePlatform: ((): string | null => {
                      if (
                        activeTab?.params &&
                        activeTab?.params['filter_label[text_game_platform]']
                      ) {
                        return activeTab?.params['filter_label[text_game_platform]'];
                      }
                      return null;
                    })(),
                    productTypes: params.filter_product_types,
                    modelName: Array.from(modelNames).join(','),
                  },
                  className: className || 'button',
                }}
              >
                {((): string => {
                  if (label) {
                    return label;
                  }
                  if (
                    dealData.deals &&
                    dealData.deals[0] &&
                    dealData.deals[0].offer &&
                    dealData.deals[0].offer.all_deals_link_text
                  ) {
                    return dealData.deals[0].offer.all_deals_link_text;
                  }
                  return translate('hawkFooterLabel');
                })()}
              </AllDeals>
            )}
            {showPoweredBy && <PoweredBy />}
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default Footer;
