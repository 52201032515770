import type { Deal } from '../types/Deal';
import type { Translate } from '../types/Localiser';
import productAmount from '../utils/productAmount';

import getMinutesValueDisplay from './getMinutesValueDisplay';

export default (deal: Deal, translate: Translate): string | number => {
  if (deal?.contract && typeof deal.contract.texts === 'number') {
    return productAmount(deal.contract.texts, translate);
  }
  if (deal?.contract && typeof deal.contract.minutes_value === 'number') {
    return getMinutesValueDisplay(deal, translate);
  }
  return '';
};
