import React from 'react';

import { getClickType } from '../../../../sharedModules/getClickType';
import type { Deal } from '../../../types/Deal';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/view-at.css';

interface ViewAtProps {
  deal: Deal;
  className?: string;
}

const ViewAt: React.FC<ViewAtProps> = ({ deal, className }) => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        articleUrl,
        translate,
        genericSharedComponents: { AffiliateLink },
      } = value;
      const containerStyle = className && styles[className] ? className : 'container';
      const merchantName = deal?.merchant?.name ?? '';

      return (
        <div className={styles[containerStyle]}>
          <span>{translate('viewAtBlockLabel')} </span>
          <AffiliateLink
            deal={deal}
            className={className || 'underline'}
            clickType={getClickType({
              link: deal.offer.link,
              articleUrl,
              productType: deal.product_type,
            })}
          >
            {merchantName}
          </AffiliateLink>
        </div>
      );
    }}
  </Consumer>
);

export default ViewAt;
