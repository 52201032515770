import type { Deal } from '../types/Deal';
import type { Translate } from '../types/Localiser';
import getCurrencySymbol from '../utils/getCurrencySymbol';
import productAmount from '../utils/productAmount';

export default (deal: Deal, translate: Translate): string | number => {
  if (deal?.contract && deal?.offer) {
    if (productAmount(deal.contract.minutes_value, translate) !== deal.contract.minutes_value) {
      return productAmount(deal.contract.minutes_value, translate);
    }
    return `${getCurrencySymbol(deal.offer.currency_iso)}${deal.contract.minutes_value}`;
  }
  return '';
};
