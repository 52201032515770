import React from 'react';

import { IRenderWidgetProps } from '../../../../assets/modules/renderWidget/model';
import { getWidgetInitialiser } from '../../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../../utils/loadCSS';
import { getInitialiser } from '../../../modules/getInitialiser';
import { GetInitialiserProps, WidgetWrapper } from '../../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../../types/GetJSON';
import { WidgetInitialiserOptions } from '../../../types/WidgetInitialiser';
import type { WidgetProps } from '../../../types/WidgetProps';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import MultiModelReviewContracts from '../Contracts/MultiModelReviewContracts';
import MultiModelReviewRetail from '../Retail/MultiModelReviewRetail';
import MultiModelReviewSubscriptions from '../Subscriptions/MultiModelReviewSubscriptions';

import styles from './styles/multi-model-review.css';

loadCSS('multimodelreview');

interface MultiModelReviewProps {
  endpoint: WidgetProps['endpoint'];
  getDealData: WidgetProps['getDealData'];
  dealData: WidgetProps['dealData'];
  getJSON: WidgetProps['getJSON'];
  sendAnalytics: WidgetProps['sendAnalytics'];
  setDealData: WidgetProps['setDealData'];
  translate: WidgetProps['translate'];
  widgetEndpoint: WidgetProps['widgetEndpoint'];
  genericSharedComponents: WidgetProps['genericSharedComponents'];
}

const MultiModelReview: React.FC<MultiModelReviewProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetAdvanced },
  } = props;
  return (
    <HawkWidgetAdvanced {...props}>
      <Consumer>
        {(value): JSX.Element => {
          const { activeTab } = value;

          return (
            <div className={styles.container}>
              {((): JSX.Element => {
                switch (activeTab?.category) {
                  case 'contracts':
                    return <MultiModelReviewContracts />;
                  case 'subscriptions':
                    return <MultiModelReviewSubscriptions />;
                  default:
                    return <MultiModelReviewRetail />;
                }
              })()}
            </div>
          );
        }}
      </Consumer>
    </HawkWidgetAdvanced>
  );
};

MultiModelReview.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: string | null,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default MultiModelReview;
