import React from 'react';

import Footer from '../../GenericComponents/Footer/Footer';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import MultiModelReviewSubscriptionsItems from './MultiModelReviewSubscriptionsItems';

const MultiModelReviewSubscriptions: React.FC = () => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        widgetTitle,
        loading,
        genericSharedComponents: { HawkTabs, Spinner, Title },
      } = value;
      let { dealData } = value;
      dealData = dealData || {};

      if (dealData && dealData.deals && dealData.deals.length > 0) {
        return (
          <div>
            {loading && <Spinner />}
            <Title title={widgetTitle} className="multimodelreview" />
            <HawkTabs />
            <MultiModelReviewSubscriptionsItems />
            <Footer className="transparent" showDescription={true} showPoweredBy={true} />
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default MultiModelReviewSubscriptions;
