import React from 'react';

import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import itemStyles from '../Main/styles/multimodel-review-items.css';

const MultiModelReviewRetailItems: React.FC = () => {
  const {
    showLink,
    translate,
    genericSharedComponents: { AllDeals, Button, GridItems, DealImage, DisplayName, MainPrice },
  } = useHawkWidgetContext();

  return (
    <GridItems
      className={{
        gridItemMain: itemStyles['grid-item-main'],
        gridItemBlock: itemStyles['grid-item-block'],
      }}
      widgetName="multimodelreview"
    >
      {(deal): JSX.Element => (
        <>
          <DealImage
            key="image"
            deal={deal}
            priority={['label', 'model', 'product', 'placeholder']}
          />
          <DisplayName key="name" deal={deal} />
          <DealImage key="merchant" deal={deal} priority={['merchant']} />
          <React.Fragment key="priceLink">
            <MainPrice deal={deal} />
            <Button deal={deal} />
          </React.Fragment>
          {showLink && (
            <AllDeals
              key="allDeals"
              className="multimodelreview"
              params={{ modelName: deal?.model }}
              deal={deal}
            >
              <span>{translate('allDealsBlockLabel')}</span>
            </AllDeals>
          )}
        </>
      )}
    </GridItems>
  );
};

export default MultiModelReviewRetailItems;
