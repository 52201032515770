import React, { useContext } from 'react';

import { Context } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import itemStyles from '../Main/styles/multimodel-review-items.css';

const MultiModelReviewSubscriptionsItems: React.FC = () => {
  const {
    genericSharedComponents: { Button, GridItems, DealImage, DisplayName, MainPrice },
  } = useContext(Context);

  return (
    <GridItems
      className={{
        gridItemMain: itemStyles['grid-item-main'],
        gridItemBlock: itemStyles['grid-item-block'],
      }}
      widgetName="multimodelreview"
    >
      {(deal): JSX.Element => (
        <>
          <DealImage key="image" deal={deal} priority={['network', 'merchant']} />
          <DisplayName key="name" deal={deal} type="product" />
          <MainPrice key="price" deal={deal} />
          <Button key="link" deal={deal} />
        </>
      )}
    </GridItems>
  );
};

export default MultiModelReviewSubscriptionsItems;
