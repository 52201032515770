import React from 'react';

import { getHoverOverItems } from '../../../../sharedModules/getHoverOverItems';
import ContractAllowance from '../../GenericComponents/ContractAllowance/ContractAllowance';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { HoverOver } from '../../GenericComponents/HoverOver';
import PriceButtonWrapper from '../../GenericComponents/PriceButtonWrapper/PriceButtonWrapper';
import itemStyles from '../Main/styles/multimodel-review-items.css';

const MultiModelReviewContractsItems: React.FC = () => {
  const {
    translate,
    params,
    showLink,
    genericSharedComponents: { AllDeals, GridItems, DealImage, DisplayName },
  } = useHawkWidgetContext();
  return (
    <GridItems
      className={{
        gridItemMain: itemStyles['grid-item-main'],
        gridItemBlock: itemStyles['grid-item-block'],
      }}
      widgetName="multimodelreview"
    >
      {(deal): JSX.Element => {
        const hoverItems = getHoverOverItems(deal);
        return (
          <>
            <DealImage
              key="image"
              deal={deal}
              priority={['label', 'model', 'product', 'placeholder']}
            />
            <DisplayName key="name" deal={deal} />
            <React.Fragment key="network">
              <DealImage deal={deal} priority={['network', 'merchant']} />
              <ContractAllowance deal={deal} type="length" />
            </React.Fragment>
            <React.Fragment key="allowance">
              <ContractAllowance
                deal={deal}
                showLabel={true}
                type="minutes"
                className="productname"
              />
              <ContractAllowance
                deal={deal}
                showLabel={true}
                type="texts"
                className="productname"
              />
              <ContractAllowance deal={deal} showLabel={true} type="data" className="productname" />
              {hoverItems.length > 0 && <HoverOver items={hoverItems} />}
            </React.Fragment>
            <PriceButtonWrapper key="priceLink" deal={deal} />
            {showLink && (
              <AllDeals
                {...{
                  key: 'allDeals',
                  className: 'multimodelreview',
                  params: {
                    productTypes: params?.filter_product_types,
                    modelName: deal?.model,
                  },
                  deal,
                }}
              >
                <span>{translate('allDealsBlockLabel')}</span>
              </AllDeals>
            )}
          </>
        );
      }}
    </GridItems>
  );
};

export default MultiModelReviewContractsItems;
