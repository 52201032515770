import React from 'react';

import { useFilterConfigs } from '../../../hooks/useFilterConfigs';
import getItemsPerRow from '../../../modules/getItemsPerRow';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import FiltersRow from './FiltersRow';
import styles from './styles/filters.css';

const Filters: React.FC = () => {
  const {
    translate,
    showFilters,
    genericSharedComponents: { AllDeals },
  } = useHawkWidgetContext();
  let { dealData, data } = useHawkWidgetContext();
  dealData = dealData || {};
  data = data || {};
  const labelFilters = useFilterConfigs(data.filters, 'label').slice(0, 4);

  if (showFilters) {
    const { title } = data;

    return (
      <div className={styles.container}>
        <div className={styles['image-heading-container']}>
          <div className={styles['heading-filters-container']}>
            <div className={styles['subheading-container']}>
              {dealData.totalDeals > 0 && (
                <AllDeals
                  {...{
                    params: {
                      modelName: title,
                    },
                  }}
                >
                  <div className={styles.label}>
                    <span>{translate('reviewFiltersLabel', [String(dealData.totalDeals)])}</span>
                  </div>
                </AllDeals>
              )}
            </div>
            <div className={styles['filters-container']}>
              {getItemsPerRow(labelFilters, 2).map((filters) => (
                <FiltersRow
                  {...{
                    filters,
                    key: filters.map((filter) => filter.filter_key).join(','),
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Filters;
