import React from 'react';

import shouldDisplayUpfrontPrice from '../../../modules/shouldDisplayUpfrontPrice';
import type { Deal } from '../../../types/Deal';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';
import { UpfrontPrice } from '../UpfrontPrice';
import ViewAt from '../ViewAt/ViewAt';

import styles from './styles/price-button-wrapper.css';

interface IPriceButtonWrapperProps {
  deal: Deal;
}

const PriceButtonWrapper: React.FC<IPriceButtonWrapperProps> = ({ deal }) => {
  const {
    genericSharedComponents: { Button, MainPrice, PriceNote },
  } = useHawkWidgetContext();

  return (
    <>
      <div className={styles['price-wrapper']}>
        {shouldDisplayUpfrontPrice(deal) && <UpfrontPrice deal={deal} className="above" />}
        <MainPrice
          {...{
            deal,
            className: 'above',
          }}
        />
        <PriceNote
          {...{
            deal,
          }}
        />
      </div>
      <div className={styles['button-wrapper']}>
        <Button
          {...{
            deal,
          }}
        />
        <ViewAt
          {...{
            deal,
          }}
        />
      </div>
    </>
  );
};

export default PriceButtonWrapper;
